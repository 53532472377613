import * as React from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import useSubscriptionStatus from '~/hooks/useSubscriptionStatus'
import useToken from '~/hooks/useToken'

const AuthContext = React.createContext()

const initialState = false

function AuthReducer(auth_token, action) {
  switch (action.type) {
    case 'login': {
      return action.auth_token
    }
    case 'logout': {
      axios
        .post(
          `${process.env.base_api}/auth/invalidate`,
          {},
          {
            headers: {
              Authorization: `Bearer ${action.auth_token}`
            }
          }
        )
        .catch(function (error) {
          const { data } = error.response
          console.log('error:%o', error)
        })
      return false
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function SubscriptionReducer(subscriptionStatus, action) {
  switch (action.type) {
    case 'update': {
      return action.subscriptionStatus
    }
    case 'playQuiz': {
      return {
        ...subscriptionStatus,
        past_quiz_count: subscriptionStatus.past_quiz_count + 1
      }
    }
    case 'nextDemoStep': {
      return {
        ...subscriptionStatus,
        demo_step: subscriptionStatus.demo_step + 1
      }
    }
    case 'previousDemoStep': {
      return {
        ...subscriptionStatus,
        demo_step: subscriptionStatus.demo_step - 1
      }
    }

    case 'endDemo': {
      return {
        ...subscriptionStatus,
        demo_step: 100
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function AuthProvider({ children }) {
  const [user, dispatch] = React.useReducer(AuthReducer, initialState)
  const token = useToken()
  const subscriptionInitialData = useSubscriptionStatus(token)

  const [subscriptionStatus, dispatchSubscriptionStatus] = React.useReducer(
    SubscriptionReducer,
    []
  )
  useEffect(() => {
    dispatchSubscriptionStatus({
      type: 'update',
      subscriptionStatus: subscriptionInitialData
    })
  }, [subscriptionInitialData])

  const value = {
    user,
    token,
    dispatch,
    subscriptionStatus,
    dispatchSubscriptionStatus
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthContext, AuthProvider, useAuth }
